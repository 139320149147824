<template>
    <div class="main_r_info_box">
        <ul v-if="articleList.length > 0" class="main_r_info_box_ul">
            <li v-for="(item,i) in articleList" :key="i">
                <a :href="item.contentIsChain == '1' ? item.contentChain : '/detail?docId='+item.id" :title="item.contentTitle" target="_blank" style="display:block;">
                    <div class="main_r_info_box_li_imgbox">
                        <img class="main_img" :src="(item.imgSrc && item.imgSrc.length > 0) ? imgBase + item.imgSrc[0] : defaultImg1" alt="">
                        <!-- <img class="video_btn" src="../../assets/imgs/video.png" alt="点击播放"> -->
                    </div>
                    <p class="main_r_info_box_li_time">{{item.contentDatetime ? item.contentDatetime.substring(0,10) : ''}}</p>
                    <h4 class="main_r_info_box_li_h4">{{item.contentTitle ? item.contentTitle : ''}}</h4>
                </a>
            </li>
        </ul>
        <div v-if="articleList.length > 0" class="page_box">
            <el-pagination
                background
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page.sync="pageNum"
                :page-size="pageSize"
                layout="prev, pager, next, jumper"
                :total="total">
            </el-pagination>
        </div>
        <el-empty v-if="articleList.length <= 0" description="暂无数据!"></el-empty>
    </div>
</template>

<script>

import $ from 'jquery';
import api from "@/../static/api/api.js";

import defaultImg1 from '@/assets/imgs/default.png'
import defaultImg2 from '@/assets/imgs/default2.png'
import defaultImg3 from '@/assets/imgs/default3.png'

export default {
    name: 'imglist',
    props:['currentId','prtitle'],
    data() {
        return {
            imgBase: this.$store.state.imgBase,
            defaultImg1: defaultImg1,//轮播图默认图片
            defaultImg2: defaultImg2,//研究项目默认图
            defaultImg3: defaultImg3,//交流传播默认图
            articleList: [],//右侧展示文章列表数据
            pageNum: 1,//分页的三个值
            pageSize: 9,
            total: 0,
            curtitle: ''
        }
    },
    watch: {
        currentId (val) {
            this.rightInfoFn(val);
        }
    },
    mounted() {
        this.rightInfoFn(this.currentId);//默认展示CIKD大讲堂
    },
    methods: {
        //加载右侧文章列表数据
        rightInfoFn(index, curNum=1){
            let _this = this;
            let menuId = index;
            let pageNum = curNum;
            let pageSize = 9;
            api.getArticleList(menuId, pageNum, pageSize).then(res => {
                if (res.data) {
                    _this.articleList = res.data.list;
                    _this.pageNum = res.data.pageNum;
                    _this.pageSize =res.data.pageSize;
                    _this.total =res.data.total;
                    console.log(res.data.list,'res.data.list');
                    console.log(res.data.title,'res.data');
                    _this.$emit('titleInfo',res.data.categoryPosition);//将当前所属栏目传给父组件
                    _this.curtitle = res.data.title;
                }
            });
        },
        //分页操作函数1
        handleSizeChange(val) {
            // console.log(`每页 ${val} 条`);
        },
        //分页操作函数2
        handleCurrentChange(val) {
            // console.log(`当前页: ${val}`);
            this.rightInfoFn(this.currentId, val);
        },
        //鼠标在video列表和是哪个hover显示btn按钮
        videoShowBtn(num, index){
            if(num == 1){
                $('.main_r_info_box_li_imgbox .video_btn').eq(index).css('display','block');
            }
            if(num == 2){
                $('.main_r_info_box_li_imgbox .video_btn').eq(index).css('display','none');
            }
        }
    }
}
</script>

<style scoped>

.main_r_info_box{
    width:100%;
    margin-top:38px;
}
.main_r_info_box_ul{
    width: 100%;
    height: 100%;
    overflow: hidden;
}
.main_r_info_box_ul li{
    width: 274px;
    height:304px;
    overflow: hidden;
    float: left;
    margin-right: 34px;
    margin-bottom: 35px;
}
.main_r_info_box_ul li:nth-child(3){
    margin-right: 0;
}
.main_r_info_box_ul li:nth-child(6){
    margin-right: 0;
}
.main_r_info_box_ul li:nth-child(9){
    margin-right: 0;
}
.main_r_info_box_li_imgbox{
    width: 100%;
    height: 185px;
    overflow: hidden;
    position: relative;
}
.main_r_info_box_li_imgbox .main_img{
    width:100%;
    display: block;
}
.main_r_info_box_li_imgbox .video_btn{
    width: 30px;
    height: 30px;
    position: absolute;
    top:66px;
    left:122px;
    display: none;
    cursor: pointer;
}
.main_r_info_box_li_time{
    width: 100%;
    height: 12px;
    font-size: 12px;
    /* font-family: STXihei; */
    font-weight: 400;
    color: #1B70CB;
    line-height: 12px;
    text-align: left;
    margin-top: 20px;
    margin-bottom: 21px;
}
.main_r_info_box_li_h4{
    width: 100%;
    height: 49px;
    font-size: 18px;
    /* font-family: STXihei; */
    font-weight: 400;
    color: #000000;
    line-height: 32px;
    text-align: left;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

/* 分页 */
.page_box{
    width:100%;
    height:63px;
    margin-top:30px;
    margin-bottom:100px;
}





</style>
